.heading{
    font-weight: 20px;
    color: tomato;
}
#botom{
    text-transform: uppercase;
    background-color: rgb(43, 48, 48);
    color: white;
}
#botom:hover{
    background-color: orange;
     color: rgb(43, 48, 48);

}
.img-fluid{
    height: 350px;
    width: 440px;
}
#head,#head1{
color: black;
}
