@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
.body{
    font-family: 'poppins',sans-serif;
}
div.head{
    
   
}
.contant{
    display: block;
    text-align: center;
  
}
h1{
    text-transform: capitalize;
    font-weight: 400px;
}
.teacher{
    margin-top: 100px;
    background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: white;

}
#teacher{
   
    display: block;
    text-align: center;
   
}
.btn{
    background-color: orange;
    text-transform: uppercase;
    font-size: small;
   
}
.btn:hover{
    background-color: white;
    color: orange;
}