*{
    margin: 0px;
    padding: 0px;
}
.footer .widget-title {
    position: relative;
    display: block;
    margin-bottom: 10px;
}
.cont{
    background-color: #4c5a7d;
}

.cont-1{
    background-color: #4c5a7d;
}

.text-color-1{
    color: rgb(238, 100, 100);
}
.nav-link{
    display: flex;
  
    
}
.link{
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    font-size: 15px;
    letter-spacing: 0.5px;

}
.link:hover{
    color: orange;
}
.titl{
    font-size: 16px;
    color: white;
}
.Social_media {
    color: white;
    padding: 5px;
    font-size: 30px;
    text-decoration: none;
    
}
.Social_media:hover{
    color: orange;
}
.footer2{
    margin:0px;
    padding-top: 10px;
    width: 100%;
    height: auto;
    background: rgb(8, 1, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
