.wrapper{
    max-height: 1000px;
    display: flex;
    overflow: auto;
    /*width: 720px;*/
   
   
}
.wrapper::-webkit-scrollbar{
  width: 1px;
}

.wrapper>.container{
  margin-left: 60px;
  margin-right: 60px;
  /* min-width: 200px;
    height: 200px;
   /* line-height: 110px;*/
    text-align: center;
   /* background-color: rgb(152, 161, 161);*/
  
}

.title{

  color: rgb(250, 250, 250);
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.teach{
  color: rgb(250, 250, 253);
  font-style: oblique;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.container {
  position: relative;
 /* width: 50%;
  max-width: 300px;*/
}

.image {
 /* display: block;
  width: 100%;
  /*height: auto;*/
  border-radius: 80%;
}

.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1; 
  /*border: 1px solid white;*/
  border-radius: 5px;
  width: 80%;
  height: 30px;
  align-items: center;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 5px;
  text-align: center;
}

.container:hover .overlay {
  opacity: 1;
}