#lab{
    color: orange;
   font-weight: 500px bold;
    font-size: 50px;

}
.bt{
  
   display: flex;
   flex-direction: row;
   gap: 1rem;
}

#bt1{
    display: flex;
    justify-content: center;
}
#bt2{
    display: flex;
    justify-content: end;
}