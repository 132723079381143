.all-title-box {
    background: url(F:\rect_js\domyproject\public\image\banner.jpg)no-repeat;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    min-height: 100px;
    opacity: 1;
    display: flex;
    align-items: center;
    padding-top: 240px;
    padding-bottom: 240px;
    height: 100px;
}
.head{
   
   
    letter-spacing: 0.5px;
    font-size: 48px;
    font-weight: bolder;
}
h3{
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0.5px;
 
}
.container {
    color: white;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    align-items: center;
    position: relative;
}
 #dis{
    margin: 10px;
    font-size: 18px;
    color: #999999;
    font-weight: bold;
    letter-spacing: 0.5px;
 }
 .contact_form .form-control {
    background-color: #fff;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    color: #bcbcbc;
    font-size: 16px;
    outline: 0 none;
    padding: 10px 25px;
    height: 55px;
    resize: none;
    box-shadow: none !important;
    width: 80%;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input {
    overflow: visible;
}
input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}
user agent stylesheet
input[type="text" i] {
    padding: 1px 2px;
}
user agent stylesheet
input {
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    width: 60px;
    border-radius: 50%;

}
.contact_form {
    background-color: none;
   width: auto;
}
#comments{
    width: 90%;
    max-height: fit-content;
    height: 180px;
    margin-bottom: 40px;
}
.pd button{
    margin-bottom: 40px;
    margin-left: 40px;
    height: 58px;
    width: 160px;
    border: 2px solid white;
    background-color: rgb(34, 33, 33);
    color: white;
    letter-spacing: 1px;
    font-weight: bold;
    border-radius: 1px;
}
.pd button:hover{
    background-color: orange;
}
.box{
    margin-top: 40px;
    margin-bottom: 40px;
   
}
.main{
    background-color: #e9ecef;
}
.title1{
    font-size: 40px;
   /* font-weight: bold;*/
    letter-spacing: 1px;
    text-transform: capitalize;
     color: #313131;
     padding-left: 35px;
     margin: 0;
}
#second{
    background-color: none;
}
#inf-title{
color: #005e44;
font-size: 18px ;
font-family: sans-serif;
margin: 0px,0px,30px;
padding: 25px,10px,25px,70px;
text-transform: capitalize;
}
.main_box{
    border: 1px,solid,red;
    height: auto;
    width: 100%;
    border: 1px solid rgb(231, 229, 229);
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.main_box.icon{
display: flex;
flex-direction: row;
}
span.icon{
    color: orange;
    font-size: 40px;
    display: inline-block;
}
#title1{
    padding: 0;
    text-transform: capitalize;
}