.header{
    background-color: #4c5a7d;
    text-decoration: none;
    color:white;
    font-weight: bold;
    text-transform: uppercase;
}
.btn{
    height: 40px;
    background-color: white;
    font-weight: bold;
}
.btn:hover{
    background-color:orange ;
}
.item:hover{
    color:orange;
  
}
.item{
    color:white;
    font-weight:500px;
    font-weight: bold;
    text-decoration: none;
   font-family: 'poppins',sans-serif;
   text-transform: uppercase;
   font-size: 18px;
   letter-spacing: 0.5px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
  
  .dropdown {
    position: relative;
    display: inline-block;
    align-items: center;
    
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
   .a{
    color: #262729;
   }
  .dropdown-content .a {
    color: rgb(82, 78, 78);
    padding: 10px 12px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content .a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  




